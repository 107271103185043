import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

class Articles extends Component {
    mainAuthorId = "60cce970906dd783d3f0b15b";

    state = {
        articleContent: "",
        articleTitle: "",
        articleDescription: "",
        articleDate: null
    }

    componentDidMount() {
        this.props.indexArticle(this.mainAuthorId);

        const query = new URLSearchParams(this.props.location.search);
        const articleId = query.get('id');

        if (articleId) {
            this.handleChoice(articleId);
        } else {
            this.setFirstArticle();
        }
    }

    componentDidUpdate(prevProps) {
        const prevQuery = new URLSearchParams(prevProps.location.search);
        const currentQuery = new URLSearchParams(this.props.location.search);

        const prevId = prevQuery.get('id');
        const currentId = currentQuery.get('id');

        if (currentId && currentId !== prevId) {
            this.handleChoice(currentId);
        }

        if (!this.state.articleContent && !currentId) {
            this.setFirstArticle();
        }
    }

    handleChoice = articleId => {
        const articleBody = this.props.article.articles[this.mainAuthorId]?.find(article => {
            return article._id === articleId;
        });

        if (articleBody) {
            this.setState({
                articleContent: articleBody.body,
                articleTitle: articleBody.title,
                articleDescription: articleBody.description,
                articleDate: articleBody.createdAt
            });
        }
    }

    setFirstArticle = () => {
        const articles = this.props.article.articles[this.mainAuthorId];
        if (!articles || articles.length === 0) return;

        const firstArticle = articles[0];
        if (firstArticle) {
            this.setState({
                articleContent: firstArticle.body,
                articleTitle: firstArticle.title,
                articleDescription: firstArticle.description,
                articleDate: firstArticle.createdAt
            });
        }
    }

    render() {
        const { classes } = this.props;

        let articles = [];

        if (this.props.article.articles[this.mainAuthorId]) {
            articles = this.props.article.articles[this.mainAuthorId]
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((article, index) => {
                    const date = new Date(article.createdAt).toLocaleDateString('en-US', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit'
                    });
                    return <ListItem
                        key={index}
                        button
                        className={classes.button}
                        onClick={() => this.handleChoice(article._id)}
                    >
                        <Typography color='inherit' className={classes.listItemText} variant='h6'>
                            {article.title} || {date}
                        </Typography>
                    </ListItem>
                });
        }

        return (
            <div className={classes.articleWrapper}>
                <Grid className={classes.articleBody} container justify='center' spacing={6}>
                    {articles.length > 0 && (
                        <Grid item style={{ textAlign: 'center' }} xs={12} sm={3}>
                            <Paper className={classes.paperContainer}>
                                <List>{articles}</List>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item style={{ textAlign: 'justify' }} xs={12} sm={9}>
                        <Paper className={classes.paperContainer}>
                            {!this.state.articleContent ? (
                                <Typography variant='h3'>Loading...</Typography>
                            ) : (
                                <>
                                    <Typography variant='h3'>{this.state.articleTitle}</Typography>
                                    <Typography variant='h6'>{this.state.articleDescription}</Typography>

                                    {this.state.articleDate && (
                                        <Typography variant="caption" display="block" gutterBottom>
                                            Bruce Fury - {new Date(this.state.articleDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </Typography>
                                    )}
                                    <div
                                        className={classes.articleTextBox}
                                        dangerouslySetInnerHTML={{ __html: this.state.articleContent }}
                                    />
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Articles);
