export const content = {
    banner: {
        'en': {
            subheading: "advanced systems | accessible prices"
        },
        'esp': {
            subheading: "sistemas avanzados | precios accesibles"
        }
    },
    contactForm: {
        'en': {
            heading: "Contact",
            subheading: "Send us a message:",
            paragraphOne: "Every project is different, demanding careful planning and requirements gathering.  Development time depends on many factors, most notably the complexity of the application, and the technologies chosen.",
            paragraphTwo: "Let us know if we can help you turn your idea into a reality by sending us a message detailing the scope and timeline of your project, and let's get started!",
            name: "Name",
            message: "Message",
            submit: "Submit",
            clearForm: "Clear Form",
            successMessage: "A team member will be in contact shortly!",
            validationMessage: "Please make sure all fields are completed and a valid e-mail address was entered."
        },
        'esp': {
            heading: "Contacto",
            subheading: "Mandanos un mensaje:",
            paragraphOne: "Cada proyecto es diferente y exige una planificación cuidadosa y la recopilación de requisitos. El tiempo de desarrollo depende de muchos factores, entre los que destaca la complejidad de la aplicación y las tecnologías elegidas",
            paragraphTwo: "Háganos saber si podemos ayudarlo a convertir su idea en realidad enviándonos un mensaje que detalle el alcance y el cronograma de su proyecto, ¡y comencemos!",
            name: "Nombre",
            message: "Mensaje",
            submit: "Enviar",
            clearForm: "Reiniciar",
            successMessage: "¡Una miembro del equipo se pondrá en contacto en breve!",
            validationMessage: "Asegúrese de completar todos los campos y de ingresar una dirección de correo electrónico válida."
        }
    },
    products: {
        'en': {},
        'esp': {}
    },
    services: {
        'en': {
            heading: "Services",
            subheading: "rapid web application development with the newest tech",
            developmentHeading: "Web Application Development",
            development: "By focusing on a specific set of technologies we can transform your idea into a polished final product.  We will guide you along the way, from conceptualizing and developing a Minimum Viable Product (MVP), to Life-Cycle Management and Product Maintenance.",

            testingHeading: "Application Testing",
            testing: "Building an application is rarely enough, it must be thoroughly tested to ensure it satisfies all requirements.  Aside from providing automated unit and integration tests with all of our software, we can systematically test 3rd-party software to verify that it conforms to all expectations.",

            consultationHeading: "Technical Consultation",
            consultation: "With so many technologies for designing web-applications available, it can be difficult to choose the correct tool, exceptionally so for the uninitiated.  We can help make sense of all of the options, and provide a high level analysis of available alternatives.",

            aIConsultationHeading: "Artificial Intelligence Consulting",
            aIConsultation: "Artificial intelligence and machine learning are becoming vital components in the digital landscape. We can help you identify the potential applications of AI in your business. From developing strategies for AI implementation to identifying the right tools and techniques, we can guide you through the complexities of this transformative technology.",

            automationConsultationHeading: "Automation Consulting",
            automationConsultation: "Automation can streamline processes, increase productivity, and reduce costs, but understanding where and how to implement it can be challenging. Our experts are here to assist you in identifying areas in your operations where automation can bring significant benefits. We’ll design a tailored automation strategy to ensure a smooth transition and successful integration.",

            technicalTrainingAndCourseDesignHeading: "Technical Training and Course Design",
            technicalTrainingAndCourseDesign: "Empowering your team with the right skills is vital in this fast-paced digital landscape. We provide comprehensive technical training courses, tailored to your organization's specific needs. From introducing specific technologies to teaching advanced programming techniques, our courses are hands-on and practical.  This comprehensive approach ensures your team has the knowledge and skills needed to excel."
        },
        'esp': {
            heading: "Servicios",
            subheading: "desarrollo rápido de aplicaciones web con la tecnología más nueva",
            developmentHeading: "Desarrollo de aplicaciones web",
            development: "Al enfocarnos en un conjunto específico de tecnologías, podemos transformar su idea en un producto final pulido. Lo guiaremos en el camino, desde la conceptualización y el desarrollo de un Producto Mínimo Viable (PMV), hasta la Gestión del Ciclo de Vida y el Mantenimiento del Producto.",

            testingHeading: "Prueba de aplicaciones",
            testing: "La creación de una aplicación rara vez es suficiente, debe probarse a fondo para garantizar que cumpla con todos los requisitos. Además de proporcionar pruebas automatizadas de unidad e integración con todo nuestro software, podemos probar sistemáticamente software de terceros para verificar que cumpla con todas las expectativas.",

            consultationHeading: "Consulta técnica",
            consultation: "Con tantas tecnologías disponibles para diseñar aplicaciones web, puede ser difícil elegir la herramienta correcta, excepcionalmente para los no iniciados. Podemos ayudar a entender todas las opciones y proporcionar un análisis de alto nivel de las alternativas disponibles .",
        
            aIConsultationHeading: "Consulta en Inteligencia Artificial",
            aIConsultation: "La inteligencia artificial y el aprendizaje automático se están convirtiendo en componentes vitales en el panorama digital. Podemos ayudarte a identificar las posibles aplicaciones de la IA en tu negocio. Desde el desarrollo de estrategias para la implementación de IA hasta la identificación de las herramientas y técnicas correctas, podemos guiarte a través de las complejidades de esta tecnología transformadora.",

            automationConsultationHeading: "Consulta de Automatización",
            automationConsultation: "La automatización puede optimizar procesos, aumentar la productividad y reducir costos, pero entender dónde y cómo implementarla puede ser un desafío. Nuestros expertos están aquí para ayudarte a identificar áreas en tus operaciones donde la automatización puede traer beneficios significativos. Diseñaremos una estrategia de automatización personalizada para garantizar una transición suave y una integración exitosa.",

            technicalTrainingAndCourseDesignHeading: "Capacitación Técnica y Diseño de Cursos",
            technicalTrainingAndCourseDesign: "Potenciar a tu equipo con las habilidades correctas es vital en este rápido panorama digital. Ofrecemos cursos de capacitación técnica integrales, adaptados a las necesidades específicas de tu organización. Desde la introducción de tecnologías específicas hasta la enseñanza de técnicas de programación avanzadas, nuestros cursos son prácticos y prácticos.  Este enfoque integral garantiza que tu equipo tenga el conocimiento y las habilidades necesarios para sobresalir.",
        }
    },
    technologies: {
        'en': {
            heading: "Technologies",
            subheading: "leveraging the most advanced technologies for optimal results",
            node: "Node.js is an immensely popular Javascript runtime environment built on Google's V8 engine, the same engine used to power the Chrome web browser.  Node provides a rock-solid foundation for any real-time application to build upon, and a host of readily available packages to solve almost any problem quickly, with an eye to performance and scalability.",
            react: "React is the front-end framework of choice for a responsive user experience.  Developed by Facebook for the demands of a highly interactive social media website, other companies including AirBnB, Uber, Netflix, Pinterest, and Instagram have all adopted React as the only means to offer the dynamic content expected from a modern website.",
            express: "Express is the de facto standard web framework built on Node.js, and for good reason.  Express provides high-performance and scalability via a micro-service architecture with a rich set of tools.  Currently running on thousands of servers, Express equips you with the out-of-box features to rapidly build a prototype or an enterprise-grade production application.",
            mongodb: "MongoDB is a NoSQL database that is rapidly becoming known for its ease of use, scalability, flexibility and extreme speed.  An alternative to more traditional SQL databases with bulky ORMs, Mongo's unique design is better suited to real-time applications that will have evolving data requirements, and need built in support for distributed systems.",
            materialui: "Material UI is a library of React components that implements Google's Material Design - a popular framework for designing user interfaces (e.g. all of Google's applications).  By using Material UI, you can considerably reduce design costs while providing a familiar and consistent experience to your users.  ",
            testing: "A well tested application will contain less bugs, higher quality software, and facilitate any future modifications or feature additions.  We can provide a complete integration testing solution with Selenium and Python.  While unit testing is ideal, we've found the most cost effective approach is to apply unit testing to critical components, and use automated browser or API based integration tests to most efficiently validate general behavior.",
            wordpress: "Wordpress has earned its place as ready made solution to website design, now occupying 29% of the web.  Initially designed for blogging, the very prolific community has created themes and plugins for almost any use-case.  Wordpress is a viable option for light to medium-duty applications that need to be built quickly.",
            phoenix: "Built on the robust Erlang VM, the Phoenix framework is a powerful web framework coded with the Elixir functional programming language.  Leveraging the power of channels, it is well suited for ultimate speed and concurrency, and designed for maximum developer productivity."
        },
        'esp': {
            heading: "Tecnologías",
            subheading: "aprovechar las tecnologías más avanzadas para obtener resultados óptimos",
            node: "Node.js es un entorno de ejecución de JavaScript inmensamente popular creado en el motor V8 de Google, el mismo motor que se utiliza para alimentar el navegador web Chrome. Node proporciona una base sólida como una roca para cualquier aplicación en tiempo real sobre la que construir, y un host de paquetes fácilmente disponibles para resolver casi cualquier problema rápidamente, con miras al rendimiento y la escalabilidad. ",
            react: "React es el marco de interfaz preferido para una experiencia de usuario receptiva. Desarrollado por Facebook para las demandas de un sitio web de redes sociales altamente interactivo, otras compañías, incluidas AirBnB, Uber, Netflix, Pinterest e Instagram, han adoptado React como el único medio para ofrecer el contenido dinámico que se espera de un sitio web moderno. ",
            express: "Express es el marco web estándar de facto construido en Node.js, y por una buena razón. Express proporciona alto rendimiento y escalabilidad a través de una arquitectura de microservicio con un amplio conjunto de herramientas. Actualmente se ejecuta en miles de servidores, Express lo equipa con las funciones listas para usar para construir rápidamente un prototipo o una aplicación de producción de nivel empresarial. ",
            mongodb: "MongoDB es una base de datos NoSQL que se está volviendo rápidamente conocida por su facilidad de uso, escalabilidad, flexibilidad y velocidad extrema. Una alternativa a las bases de datos SQL más tradicionales con ORM voluminosos, el diseño único de Mongo se adapta mejor a aplicaciones en tiempo real que tienen requisitos de datos en evolución y necesitan soporte integrado para sistemas distribuidos. ",
            materialui: "Material UI es una biblioteca de componentes de React que implementa Material Design de Google, un marco popular para diseñar interfaces de usuario (p. ej., todas las aplicaciones de Google). Al usar Material UI, puedes reducir considerablemente los costos de diseño mientras brindas una experiencia familiar y consistente a sus usuarios. ",
            testing: "Una aplicación bien probada contendrá menos errores, software de mayor calidad y facilitará futuras modificaciones o adiciones de funciones. Podemos proporcionar una solución de prueba de integración completa con Selenium y Python. Hemos encontrado la mayoría El enfoque rentable consiste en aplicar pruebas unitarias a los componentes críticos y utilizar pruebas de integración basadas en navegadores o API automatizadas para validar el comportamiento general de la manera más eficiente. ",
            wordpress: "Wordpress se ha ganado su lugar como solución prefabricada para el diseño de sitios web, que ahora ocupa el 29% de la web. Inicialmente diseñada para blogs, la muy prolífica comunidad ha creado temas y complementos para casi cualquier caso de uso. Wordpress es una opción viable para aplicaciones ligeras a medianas que deben construirse rápidamente. ",
            phoenix: "Construido sobre la robusta Erlang VM, el marco Phoenix es un marco web poderoso codificado con el lenguaje de programación funcional Elixir. Aprovechando el poder de los canales, es muy adecuado para la máxima velocidad y concurrencia, y está diseñado para la máxima productividad del desarrollador.",
        }
    },
    updates : {
        'en': {
            heading: "Latest Articles",
            subheading: "insightful guides, big ideas, and a dash of tech culture",
        },
        'esp' : {
            heading: "Últimos Artículos",
            subheading: "guías detalladas, grandes ideas y una pizca de cultura tecnológica",
        }
    }

}
