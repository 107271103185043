import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

//import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';

import LanguageChooser from '../../LanguageChooser';
import { content } from '../../content';

class MenuBar extends Component {

    render() {
        const { classes, config } = this.props;
        const isLoggedIn = this.props.auth.isLoggedIn;

        const { language } = config;
        const sectionContent = content.menuBar[language];

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Link
                            scroll={el => el.scrollIntoView({ behavior: "smooth" })}
                            to="/home"
                            className={classes.link}>

                            <Button className={classes.button}>
                                {sectionContent.home}
                            </Button>
                        </Link>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#services"
                            className={classes.link}>
                            <Button color="secondary" className={classes.button}>
                            {sectionContent.services}
                            </Button>
                        </Link>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#articles"
                            className={classes.link}
                        >
                            <Button color="secondary" className={classes.button}>
                                {sectionContent?.updates || 'Updates'}
                            </Button>
                        </Link>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#contact"
                            className={classes.link}>
                            <Button color="secondary" className={classes.button}>
                            {sectionContent.contact}
                            </Button>
                        </Link>
                        <Link to="/articles" className={classes.link}>
                            <Button color="secondary" className={classes.button}>
                            {sectionContent.articles}
                            </Button>
                        </Link>
                        {isLoggedIn ? (
                            <Link to="/dashboard" className={classes.link}>
                                <IconButton className={classes.menuButton} aria-label="Dashboard">
                                    <DashboardIcon />
                                </IconButton>
                            </Link>
                        ) : ("")}
                        <LanguageChooser />
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(MenuBar));
