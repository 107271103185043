import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//import { HashLink as Link } from 'react-router-hash-link';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


class Footer extends Component {
    state = {
        auth: true,
        anchorEl: null,
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleSigninClick = () => {
        this.props.history.push("/auth/signin");
        this.setState({ anchorEl: null });
    }

    handleSignupClick = () => {
        this.props.history.push("/auth/signup");
        this.setState({ anchorEl: null });
    }

    handleSignoutClick = () => {
        this.props.signoutUser();
        this.props.history.push("/home");
        this.setState({ anchorEl: null });
    }

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const isLoggedIn = this.props.auth.isLoggedIn;

        return (
            <Grid container className={classes.root} justify="center">
                <Grid item xs={12} sm={4}>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container justify="center">
                        <Grid item style={{ textAlign: 'center', color: 'white' }} xs={12}>
                            <Hidden xsDown={true}>
                                <Typography variant="h6" gutterBottom>
                                    © 2016 - 2025 Atomic EIRL
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    RUC: 20608105329
                                </Typography>
                            </Hidden>
                            <Hidden smUp={true}>
                                <Typography variant="body1" gutterBottom>
                                    © 2016 - 2025
                                </Typography>
                                <Typography className={classes.mobileCopyright} variant="body1" gutterBottom>
                                    Atomic EIRL
                                </Typography>
                                <Typography className={classes.mobileCopyright} variant="body1" gutterBottom>
                                    RUC: 20608105329
                                </Typography>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden xsDown>
                    <Grid item xs={12} sm={4}>
                        <Grid container justify="flex-end">
                            <Grid item style={{ textAlign: 'center' }} xs={1}>
                                <IconButton
                                    className={classes.menuButton}
                                    aria-owns={open ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="secondary">
                                    <AccountBoxIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}>
                                    {isLoggedIn ? ([<MenuItem onClick={this.handleSignoutClick} key={"signout"}>Sign Out</MenuItem>]) :
                                        ([<MenuItem onClick={this.handleSigninClick} key={"signin"}>Sign In</MenuItem>])}
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        );
    }
}

export default withStyles(styles)(withRouter(Footer));
