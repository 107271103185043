import React, { Component } from 'react';

import Banner from './Banner'
import Services from './Services'
import LatestArticles from './LatestArticles';
import ContactForm from './ContactForm'

class Home extends Component {
    render() {
        const { config } = this.props;

        return (
            <React.Fragment>
                <Banner config={config} />
                <Services config={config} />
                <LatestArticles config={config} />
                <ContactForm config={config} />
            </React.Fragment>
        );
    }
}

export default Home;
