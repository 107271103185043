import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/ArticleFormStyles';

import { isEmpty } from '../../../../../utility';

class ArticleForm extends Component {
    state = {
        fields: {
            title: "",
            description: "",
            longDescription: "",
            body: "",
            articleId: null
        },
    };

    componentDidUpdate(prevProps) {
        const articleIdChanged = prevProps.editArticleId !== this.props.editArticleId;
        if (articleIdChanged && !isEmpty(this.props.editArticle)) {
            this.setState({
                fields: {
                    title: this.props.editArticle.title,
                    description: this.props.editArticle.description,
                    longDescription: this.props.editArticle?.longDescription || "",
                    body: this.props.editArticle.body,
                    articleId: this.props.editArticleId,
                }
            })
        } else if (articleIdChanged && this.props.editArticleId == null) {
            this.clearFields();
        }
    };

    clearFields = () => {
        this.setState({
            fields: {
                title: "",
                description: "",
                longDescription: "",
                body: "",
                articleId: null
            }
        });
    }

    handleSubmitClick = () => {
        if (this.state.fields.articleId) {
            this.props.updateArticle(
                this.props.user.userId,
                this.state.fields.articleId,
                this.state.fields,
                this.props.user.token
            );
            this.props.clearArticleForm();
            this.clearFields();
        } else {
            const data = {
                title: this.state.fields.title,
                description: this.state.fields.description,
                longDescription: this.state.fields.longDescription,
                body: this.state.fields.body
            };
            this.props.createArticle(
                this.props.user.userId,
                data,
                this.props.user.token
            );
            this.props.clearArticleForm();
            this.clearFields();
        }
    };

    handleClearFormClick = () => {
        this.setState({
            fields: {
                title: "",
                description: "",
                longDescription: "",
                body: "",
                articleId: this.props.editArticleId ? this.props.editArticleId : null
            }
        });
    }

    handleNewArticleClick = () => {
        this.props.clearArticleForm();
        this.clearFields();
    }

    handleChange = name => event => {
        const newFields = { ...this.state.fields };
        newFields[name] = event.target.value;
        this.setState({ fields: { ...newFields } });
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <p>{this.state.fields.articleId ? `Editing Article "${this.state.fields.title}"` : `New Article`}</p>
                <form noValidate autoComplete="off">
                    <Grid container justify="center" spacing={6}>
                        <Grid item className={classes.item} xs={12}>
                            <TextField
                                id="standard-textarea"
                                label="Title"
                                placeholder="Title"
                                fullWidth
                                className={classes.textField}
                                value={this.state.fields.title}
                                onChange={this.handleChange('title')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item className={classes.item} xs={12}>
                            <TextField
                                id="standard-textarea"
                                label="Description"
                                placeholder="Description"
                                multiline
                                fullWidth
                                className={classes.textField}
                                value={this.state.fields.description}
                                onChange={this.handleChange('description')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item className={classes.item} xs={12}>
                            <TextField
                                id="standard-textarea"
                                label="Long Description"
                                placeholder="Long Description"
                                multiline
                                fullWidth
                                className={classes.textField}
                                value={this.state.fields.longDescription}
                                onChange={this.handleChange('longDescription')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item className={classes.item} xs={12}>
                            <TextField
                                id="standard-textarea"
                                label="Body"
                                placeholder="Body"
                                multiline
                                fullWidth
                                className={classes.textField}
                                value={this.state.fields.body}
                                onChange={this.handleChange('body')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid container justify="center" spacing={6}>
                    <Grid item className={classes.item} xs={4}>
                        <Button onClick={this.handleSubmitClick}>Submit</Button>
                    </Grid>
                    <Grid item className={classes.item} xs={4}>
                        <Button onClick={this.handleClearFormClick}>Clear Form</Button>
                    </Grid>
                    <Grid item className={classes.item} xs={4}>
                        <Button onClick={this.handleNewArticleClick}>New Article</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(ArticleForm));
