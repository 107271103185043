import * as actionTypes from './actionTypes';

//SYNC
//INDEX
export const requestIndexArticle = () => ({
    type: actionTypes.REQUEST_INDEX_ARTICLE,
    payload: {}
});
export const successIndexArticle = (userId, data) => ({
    type: actionTypes.SUCCESS_INDEX_ARTICLE,
    payload: { userId, data }
});
export const failIndexArticle = (error) => ({
    type: actionTypes.FAIL_INDEX_ARTICLE,
    payload: { error }
});

//FETCH
export const requestFetchArticle = (articleId) => ({
    type: actionTypes.REQUEST_FETCH_ARTICLE,
    payload: { articleId }
});
export const successFetchArticle = (data) => ({
    type: actionTypes.SUCCESS_FETCH_ARTICLE,
    payload: { data }
});
export const failFetchArticle = (error) => ({
    type: actionTypes.FAIL_FETCH_ARTICLE,
    payload: { error }
});

//CREATE
export const requestCreateArticle = (userId) => ({
    type: actionTypes.REQUEST_CREATE_ARTICLE,
    payload: { userId }
});
export const successCreateArticle = (userId, data) => ({
    type: actionTypes.SUCCESS_CREATE_ARTICLE,
    payload: { userId, data }
});
export const failCreateArticle = (error) => ({
    type: actionTypes.FAIL_CREATE_ARTICLE,
    payload: { error }
});

//UPDATE
export const requestUpdateArticle = (userId, articleId) => ({
    type: actionTypes.REQUEST_UPDATE_ARTICLE,
    payload: { userId, articleId }
});
export const successUpdateArticle = (userId, data) => ({
    type: actionTypes.SUCCESS_UPDATE_ARTICLE,
    payload: { userId, data }
});
export const failUpdateArticle = (error) => ({
    type: actionTypes.FAIL_UPDATE_ARTICLE,
    payload: { error }
});

//DELETE
export const requestDeleteArticle = (userId, articleId) => ({
    type: actionTypes.REQUEST_DELETE_ARTICLE,
    payload: { userId, articleId }
});
export const successDeleteArticle = (userId, articleId) => ({
    type: actionTypes.SUCCESS_DELETE_ARTICLE,
    payload: { userId, articleId }
});
export const failDeleteArticle = (error) => ({
    type: actionTypes.FAIL_DELETE_ARTICLE,
    payload: { error }
});


//ASYNC
//FETCH ALL ARTICLES BY USER ID
export const indexArticle = (userId) => {
    return (dispatch, getState, api) => {
        dispatch(requestIndexArticle())
        return api.indexArticle(userId)
            .then((response) => {
                if (response.status === 'success') {
                    dispatch(successIndexArticle(userId, response.data))
                } else {
                    dispatch(failIndexArticle(response.error))
                }
            })
    }
}

export const fetchArticle = (userId, articleId) => {
    return (dispatch, getState, api) => {
        dispatch(requestFetchArticle(articleId))
        return api.indexFetch(userId, articleId)
            .then((response) => {
                if (response.status === 'success') {
                    dispatch(successFetchArticle(response.data))
                } else {
                    dispatch(failFetchArticle(response.error))
                }
            })
    }
}

export const createArticle = (userId, data, token) => {
    return (dispatch, getState, api) => {
        dispatch(requestCreateArticle(userId))
        return api.createArticle(userId, data, token)
            .then((response) => {
                if (response.status === 'success') {
                    dispatch(successCreateArticle(userId, response.data))
                } else {
                    dispatch(failCreateArticle(response.error))
                }
            })
    }
}

export const updateArticle = (userId, articleId, data, token) => {
    return (dispatch, getState, api) => {
        dispatch(requestUpdateArticle(userId, articleId));
        return api.updateArticle(userId, articleId, data, token)
            .then((response) => {
                if (response.status === 'success') {
                    dispatch(successUpdateArticle(userId, response.data));
                } else {
                    dispatch(failUpdateArticle(response.error));
                }
            })
    }
}

export const deleteArticle = (userId, articleId, token) => {
    return (dispatch, getState, api) => {
        dispatch(requestDeleteArticle(articleId));
        return api.deleteArticle(userId, articleId, token)
            .then((response) => {
                if (response.status === 'success') {
                    dispatch(successDeleteArticle(userId, articleId));
                } else {
                    dispatch(failDeleteArticle(response.error));
                }
            })
    }
}
